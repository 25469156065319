@tailwind base;
@tailwind components;
@tailwind utilities;

/* GitHub code style and syntax */
@import 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/github.min.css';

/* Default browser focus style 
 ** !important necessary to overwrite default styles that includes outline:none
*/
.focus-default:focus,
label.focus-default:focus-within {
  outline: 5px auto -webkit-focus-ring-color !important;
}

/* 
 Remove outlines and focus rings on buttons and various elements for users that are NOT navigating via keyboard (i.e., via touch or mouse click).
*/
:focus:not(:focus-visible),
.focus-default:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none;
}

.homepage-section-pinpoint-header {
  @apply text-4xl font-semibold text-white md:text-5xl;
  @apply max-w-2xl mb-6;
}

.homepage-section-pinpoint-header b {
  @apply font-semibold text-brightBlue-500;
}

code.hljs {
  @apply font-normal;
}

.ml-timeline-track {
  margin-left: 0.85rem;
}

.bullet-point-after:after,
.bullet-point-before:before {
  content: '\2022';
  font-size: 0.6rem;
  @apply inline-flex items-center mx-3 text-primaryBorder;
}

.remove-last-child-border:last-child {
  border: 0 none !important;
}

.remove-last-child-margin-bottom:last-child {
  margin-bottom: 0 !important;
}

.object-svg svg {
  fill: currentColor;
}

/* remove button focus outline */
[role='button'],
button {
  @apply focus:z-auto;
}

/* remove mapbox logo */
.mapboxgl-ctrl-logo {
  display: none !important;
}

/* Hide Drift chat widget */
#drift-frame-controller.hide-drift,
#drift-frame-chat.hide-drift {
  display: none;
}

/* https://github.com/tailwindlabs/tailwindcss/discussions/3275 */
.spin-button-none {
  -moz-appearance: textfield;
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}

/* overlay for modals */
/* uses paragraph-800 */

.overlay {
  background: #4f5e70;
  opacity: 40%;
}

/* range slider component */
input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}

input[type='range']::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}

input[type='range']::-ms-track {
  appearance: none;
  height: 5px;
}

input[type='range']::-webkit-slider-thumb {
  @apply appearance-none w-4 h-4 shadow-md border-primaryBorder  border-2 border-solid bg-primaryBackground rounded-lg pointer-events-auto cursor-pointer -mt-[6px];
}

input[type='range']::-moz-range-thumb {
  @apply appearance-none w-4 h-4 shadow-md border-primaryBorder  border-2 border-solid bg-primaryBackground rounded-lg pointer-events-auto cursor-pointer -mt-[6px];
}

input[type='range']::-ms-thumb {
  @apply appearance-none w-4 h-4 shadow-md border-primaryBorder  border-2 border-solid bg-primaryBackground rounded-lg pointer-events-auto cursor-pointer -mt-[6px];
}

input[type='range']:active::-webkit-slider-thumb {
  @apply border-tmBlue;
}

/* END range slider component */

/* remove caret from mapbox popup */
.mapboxgl-popup-tip {
  display: none;
}

/* Invert filter */
.invert-filter {
  display: inline-block;
  -webkit-filter: invert(1);
  filter: invert(1);
}
